













































































































































import { useCurrency } from '@/composition/currency'
import {
  PropertyCategoryId,
  PropertyViewModel,
} from '@/services/modules/property'
import {
  getPropertyRouteTo,
  getPropertyPhotoSlideShowRouteTo,
} from '@/utils/property'
import PropertyPhotoSlideShow from '@/components/PropertyPhotoSlideShow.vue'
import { useWindowKeyboardListener } from '@/composition/windowKeyboardListener'
import router from '@/router'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
} from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyPhotoTour',

  components: {
    PropertyPhotoSlideShow,
  },

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },

    imageKey: {
      type: Number as PropType<number | null>,
      default: null,
    },

    scrollToImageKey: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },

  setup(props) {
    const propertyImageListCount = computed(
      () => props.estateProperty.propertyImageList.length
    )

    const propertyPrice = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyPrice2 = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price2,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const isRental = computed(
      () =>
        props.estateProperty.categoryViewModel.categoryId ===
        PropertyCategoryId.Rentals
    )

    onMounted(() => {
      if (props.scrollToImageKey) {
        const photoEl = document.getElementById(
          getImageId(props.scrollToImageKey)
        )

        if (photoEl) {
          photoEl.scrollIntoView()
        }
      }
    })

    function getImageId(imageKey: number) {
      return `photo-${imageKey}`
    }

    const closeRouteTo = computed(() =>
      getPropertyRouteTo(props.estateProperty)
    )

    useWindowKeyboardListener({
      key: 'Escape',
      handler: (evt) => {
        if (
          evt &&
          evt.target &&
          ['INPUT', 'TEXTAREA'].includes((evt.target as HTMLElement).nodeName)
        )
          return

        router.push(closeRouteTo.value)
      },
    })

    return {
      propertyImageListCount,
      propertyPrice,
      propertyPrice2,
      isRental,
      getImageId,
      getPropertyPhotoSlideShowRouteTo,
      closeRouteTo,
    }
  },
})
