







































































































































































































import { useCurrency } from '@/composition/currency'
import {
  PropertyCategoryId,
  PropertyViewModel,
} from '@/services/modules/property'
import {
  getPropertyRouteTo,
  getPropertyPhotoSlideShowRouteTo,
} from '@/utils/property'
import { Swiper } from 'vue-awesome-swiper'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyPhotoSlideShow',

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },

    imageKey: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const propertyImageIndex = computed(() => {
      const imageIndex = props.estateProperty.propertyImageList.findIndex(
        (image) => image.imageKey === props.imageKey
      )
      return imageIndex === -1 ? 0 : imageIndex
    })

    const propertyImage = computed(
      () => props.estateProperty.propertyImageList[propertyImageIndex.value]
    )

    const previousPropertyImageRouteTo = computed(() =>
      propertyImageIndex.value === 0
        ? undefined
        : getPropertyPhotoSlideShowRouteTo(
            props.estateProperty.propertyImageList[propertyImageIndex.value - 1]
          )
    )

    const nextPropertyImageRouteTo = computed(() =>
      propertyImageIndex.value ===
      props.estateProperty.propertyImageList.length - 1
        ? undefined
        : getPropertyPhotoSlideShowRouteTo(
            props.estateProperty.propertyImageList[propertyImageIndex.value + 1]
          )
    )

    const propertyPrice = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyPrice2 = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price2,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const isRental = computed(
      () =>
        props.estateProperty.categoryViewModel.categoryId ===
        PropertyCategoryId.Rentals
    )

    const closeRouteTo = computed(() =>
      getPropertyRouteTo(props.estateProperty)
    )

    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }))

    return {
      propertyImage,
      propertyPrice,
      propertyPrice2,
      isRental,
      previousPropertyImageRouteTo,
      nextPropertyImageRouteTo,
      closeRouteTo,
      swiper,
      swiperOptions,
    }
  },
})
